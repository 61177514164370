import React, { Fragment } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLightbulbOn } from "@fortawesome/pro-light-svg-icons/faLightbulbOn";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons/faQuestionCircle";
import { faUserCheck } from "@fortawesome/pro-light-svg-icons/faUserCheck";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons/faCalendarAlt";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons/faTrashAlt";

export default function HowItWorks() {
  return (
    <Fragment>
      <SEO title="How It Works" />
      <Layout fluid={false}>
        <h1>How it works</h1>
        <div><FontAwesomeIcon icon={faLightbulbOn} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>
        You want to get rid of your junk and you want it done fast.  We are here to help.  Follow these four simple steps… 
        </p>
        <h2>Step 1 – Decide on a Dumpster or Junk Removal</h2>
        <div><FontAwesomeIcon icon={faQuestionCircle} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>
          <i>Dumpster Rental</i><br /> Do you have the time, desire, and ability to load up your items yourself?  If you do, your best and most affordable option is the dumpster rental.  You will load the items at your own pace.  Just make sure you have the room for the dumpster and you will be fine.
        </p>
        <p>
          <i>Junk Removal</i><br />
          Are you pressed for time?  Do you have some items you can’t handle yourself?  Do you only have a few items and not enough for a full dumpster?  If so, call in the big guns and hire a junk removal service to do the work.  Your back with thank you.  Trust me.
        </p>
        <h2>Step 2 – Decide on a Screened and Approved Company</h2>
        <div><FontAwesomeIcon icon={faUserCheck} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>We will provide you with some options to choose from.  Each approved company has a profile page with reviews, pricing, and scheduling options for you to make an informed decision.  Pick from the list and rest assured that your satisfaction is our main concern.  Each company is screened and approved in order to be in our network.  Insurance requirements and background checks are performed during the screening process.</p>
        <h2>Step 3 – Scheduling</h2>
        <div><FontAwesomeIcon icon={faCalendarAlt} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>Scheduling is done right on our website.  Enter your information and pick a day and time that is convenient.  You will be provided with a 30-minute arrival time window.  A confirmation will be sent to you as a reminder of your appointment.</p>
        <h2>Step 4 – Say Goodbye to your junk</h2>
        <div><FontAwesomeIcon icon={faTrashAlt} size="lg" color="#000099" style={{marginLeft: "auto", display: "block"}}/></div>
        <hr />
        <p>On the day of your dumpster rental or junk removal, your chosen provider will reach out to you as they are en route.  Provide any direction that may be needed for your situation and prepare to have your life thinned out of some junk.  Once you have basked in the glory of less junk, feel free to fill out our feedback form to share with the community.  They will appreciate it.</p>
      </Layout>
    </Fragment>
  )
}
